import React, { useEffect, useState } from 'react'
import PieChart from './PieChart'
import { dashboardData } from '../../api_fetch/dashboard'
import CountUp from 'react-countup';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
    const [data, setData] = useState(null);
    const navigate = useNavigate();
    const fetchData = async () => {
        const rs = await dashboardData();
        const transformedData = { payments: {}, courses: {} };

        for (const key in rs.data.data) {
            if (key.startsWith("total_payments")) {
                transformedData.payments[key] = rs.data.data[key];
            } else if (key.startsWith("total_courses")) {
                transformedData.courses[key] = rs.data.data[key];
            }
        }
        setData(transformedData);

    }
    useEffect(() => {
        fetchData();
    }, [])
    return (
        <div className='p-4'>
            <h2 className="text-2xl font-bold mb-4">Dashboard</h2>
            <div className="md:flex w-full gap-5">
                <div className="bg-gray-700 p-3 rounded-xl md:w-1/2 w-full mt-5">
                    <div className="flex flex-col items-justify">
                        <div className="bg-gray-900 rounded-md p-2">Total Payments</div>
                        <div className="flex justify-center items-center mt-10">
                            <div className="w-60 h-60">
                                {data && <PieChart product="payments" data={data} />}
                            </div>
                        </div>

                    </div>
                </div>
                <div className="bg-gray-700 p-3 rounded-xl md:w-1/2 w-full mt-5">
                    {/* CardHeader with group-hover styles */}
                    <div className="bg-gray-900 text-white rounded-md p-2 group-hover:bg-black group-hover:text-white transition-colors duration-[1500ms]">
                        Total Courses
                    </div>
                    <div className="flex justify-center items-center">
                        <div className="w-full h-60 hover:bg-gray-600 mt-2 rounded-xl cursor-pointer flex justify-center items-center text-5xl font-bold"
                            onClick={() => {
                                navigate("/all-courses")
                            }}
                        >
                            <CountUp
                                start={0}
                                end={data?.courses.total_courses}
                                duration={10} // Duration in seconds
                                separator=","
                            />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Dashboard