import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { deletebanners, getbanners } from '../../api_fetch/banner';
import { FaTrashCan } from "react-icons/fa6";

const AllBanners = () => {
    const [banners, setBanners] = useState(null);
    const navigate = useNavigate();
    const fetchBanners = async () => {
        try {
            const response = await getbanners();
            setBanners(response.data.data)

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        fetchBanners();
    }, [])

    const handleDelete = async (id) => {
        const response = await deletebanners(id);
        if (response.status === 200) {
            setBanners(banners.filter(item => item.id !== id))
        }
    }
    return (
        <div className='md:my-5'>
            <div className='md:block flex justify-between items-center'>
                <h2 className="text-2xl font-bold mb-4 select-none">Banners</h2>
                <div className='text-end'>
                    <button className='bg-green-500 font-semibold py-1 px-3 mb-5 mr-10 rounded-md hover:bg-green-700'
                        onClick={() => {
                            navigate("/addBanner")
                        }}

                    >Add New Banner</button>
                </div>
            </div>
            <div className=''>
                {banners?.map((item, index) => (
                    <div className="relative w-full md:h-72 h-48 mt-5 group" key={index}>
                        <img
                            src={item.image_url}
                            alt={item.id}
                            key={index}
                            className="w-full h-full object-fit border-2 border-gray-600 rounded-lg"
                        />
                        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-0 group-hover:opacity-20 transition-opacity rounded-lg"></div>
                        <div className="absolute top-2 right-2 opacity-0 group-hover:opacity-100 transition-opacity">
                            <button
                                className="text-red-500 z-20"
                                onClick={() => handleDelete(item.id)}
                            >
                                <FaTrashCan />
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default AllBanners