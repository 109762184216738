import React, { useEffect, useState } from 'react';
import { add_courses, edit_course, singleCourse } from '../../../api_fetch/courses';
import { useNavigate, useParams } from 'react-router-dom';

const Add_Courses = () => {
    const [course, setCourse] = useState({
        title: '',
        price: '',
        description: '',
        imageUrl: null,
    });
    const [autoselectimage, setAutoselectimage] = useState();
    const params = useParams();

    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value, files } = e.target;

        if (name === 'imageUrl') {
            const file = files[0];
            if (file) {
                const allowedTypes = ["image/jpeg", "image/png", "image/jpg", "image/gif"];
                if (!allowedTypes.includes(file.type)) {
                    alert("The image field must be a file of type: jpeg, png, jpg, gif.");
                    return;
                }
                setCourse({
                    ...course,
                    imageUrl: file,
                });
            }
        } else {
            setCourse({
                ...course,
                [name]: value,
            });
        }
    };


    const getSingleCourse = async () => {
        const res = await singleCourse(params.id);
        setCourse((old) => ({
            ...old,
            title: res.data.course.title,
            description: res.data.course.description,
            price: res.data.course.price,
        }));
        setAutoselectimage(res.data.course.image);

    }

    useEffect(() => {
        if (params.update) {
            getSingleCourse();
        }
    }, [params.update])

    const handleSubmit = async (e) => {
        e.preventDefault();

        const priceNumber = parseFloat(course.price);

        if (isNaN(priceNumber)) {
            alert('Please enter a valid price');
            return;
        }

        const formdata = new FormData();
        formdata.append("title", course.title);
        formdata.append("description", course.description);
        formdata.append("price", priceNumber);
        if (course.imageUrl) {
            formdata.append("image", course.imageUrl);
        }
        if (!params.update) {
            formdata.append("category_id", params.id);
        }

        try {
            let res;
            if (params.update) {
                // const newFormdata = new FormData();
                // formdata.append("image", course.imageUrl);
                res = await edit_course(formdata, params.id);
            }
            else {
                res = await add_courses(formdata);
            }

            if (res.status === 201 || res.status === 200) {
                navigate('/all-courses');
            }
        } catch (error) {
            console.error('Error adding course:', error);
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen">
            <div className="w-full max-w-lg bg-gray-800 p-8 rounded-lg shadow-lg">
                <h2 className="text-3xl font-bold text-white mb-6 text-center">{params?.update === "update" ? "Update " : "Add New"} Course</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="title" className="block text-white font-semibold mb-2">Course Title</label>
                        <input
                            type="text"
                            id="title"
                            name="title"
                            value={course.title}
                            onChange={handleChange}
                            className="w-full px-4 py-2 text-white bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter Course Title"
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="price" className="block text-white font-semibold mb-2">Course Price</label>
                        <input
                            type="text"
                            id="price"
                            name="price"
                            value={course.price}
                            onChange={handleChange}
                            className="w-full px-4 py-2 text-white bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter Course Price"
                        />
                    </div>

                    <div className="mb-4">
                        <label htmlFor="imageUrl" className="block text-white font-semibold mb-2">Select Course Image</label>
                        <input
                            type="file"
                            id="imageUrl"
                            name="imageUrl"
                            accept="image/*" // Accept only images
                            onChange={handleChange}
                            className="w-full px-4 py-2 text-white bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>

                    <div>
                        {(course.imageUrl || autoselectimage) && <img src={!course.imageUrl && autoselectimage ? autoselectimage : URL.createObjectURL(course.imageUrl)} alt="Selected Course" className="mt-4 w-32 h-32 object-cover" />}
                    </div>

                    <div className="mb-6">
                        <label htmlFor="description" className="block text-white font-semibold mb-2">Course Description</label>
                        <textarea
                            id="description"
                            name="description"
                            value={course.description}
                            onChange={handleChange}
                            className="w-full px-4 py-2 text-white bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter Course Description"
                            rows="5"
                        />
                    </div>

                    <div className="text-center">
                        <button
                            type="submit"
                            className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            {params?.update === "update" ? "Update " : "Add "} Course
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Add_Courses;
