const initialData = {
    lectureData: {},
}

export const reducer = (state = initialData, action) => {
    switch (action.type) {
        case "LECTURE_DATA":
            return { ...state, lectureData: action.data };
        default:
            return state;
    }
}