import './App.css';
import { Route, Routes, Navigate, useNavigate } from 'react-router';
import Login from './Components/Login';
import Layout from './Components/Layout';
import Dashboard from './Pages/Dashboard';
import Payments from './Pages/Payment';
import Userlist from './Pages/UserList';
import { useAuthCheck } from './utils/auth';
import Categories from './Pages/Categories';
import All_Courses from './Pages/All_Courses';
import Add_Courses from './Pages/All_Courses/Add_courses';
import Leactures from './Pages/Lectures';
import AddLecture from './Pages/Lectures/AddLecture';
import AddCategory from './Pages/Categories/AddCategory';
import AllBanners from './Pages/Banner';
import AddBanner from './Pages/Banner/AddBanner';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect, useState } from 'react';

function App() {
  const isAuthenticated = useAuthCheck();
  console.log(isAuthenticated);

  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();


  useEffect(() => {
    if (!isAuthenticated) {
      const timer = setTimeout(() => {
        setIsLoading(false);
        navigate('/login');
      }, 2000);

      return () => clearTimeout(timer);
    } else {
      setIsLoading(false);
    }
  }, [isAuthenticated, navigate]);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen text-white">
        Loading...
      </div>
    );
  }

  return (
    <div className="bg-black text-white min-h-screen">
      <ToastContainer position="top-right" autoClose={3000} key={Math.random()} />
      <Routes>
        {isAuthenticated ? (
          <>
            <Route path='/' element={<Layout />}>
              <Route index element={<Dashboard />} />
              <Route path="payments" element={<Payments />} />
              <Route path="userlist" element={<Userlist />} />
              <Route path="category" element={<Categories />} />
              <Route path="add-category" element={<AddCategory />} />
              <Route path="all-courses" element={<All_Courses />} />
              <Route path="add-courses/:id" element={<Add_Courses />} />
              <Route path="add-courses/:update/:id" element={<Add_Courses />} />

              {/* Only wrap these routes with Redux Provider */}
              <Route
                path="lectures/:id"
                element={
                  <Provider store={store}>
                    <Leactures />
                  </Provider>
                }
              />
              <Route
                path="add-lecture/:update/:id"
                element={
                  <Provider store={store}>
                    <AddLecture />
                  </Provider>
                }
              />
              <Route path="add-lecture/:id" element={<AddLecture />} />

              <Route path="allBanners" element={<AllBanners />} />
              <Route path="addBanner" element={<AddBanner />} />
            </Route>
            <Route path="*" element={<Navigate to="/" />} />
          </>
        ) : (
          <>
            <Route path='/login' element={<Login />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </>
        )}
      </Routes>
    </div>
  );
}

export default App;
