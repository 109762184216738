import React, { useEffect, useState } from 'react';
import { dummyUsers } from '../../constant';
import { users } from '../../api_fetch/users';

const Userlist = () => {
    const [user, setUsers] = useState();

    const usersData = async () => {
        const res = await users();
        console.log(res);
        
        setUsers(res.data.data)
    }
    useEffect(() => {
        usersData();
    }, []);
    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">User Profiles</h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4">
                {user?.map((user) => (
                    <div key={user.id} className="bg-white p-4 rounded-lg shadow-md">
                        <div className="flex items-center mb-4">
                            <img
                                src={user?.image}
                                alt={`${user.name}`}
                                className="w-16 h-16 rounded-full mr-4"
                            />
                            <div>
                                <h3 className="font-semibold text-black">{`${user.name}`}</h3>
                                <p className="text-gray-600 text-xs">{user.email}</p>
                            </div>
                        </div>
                        <p className="text-gray-600 lg:text-base text-sm"><strong>Phone:</strong> {user.contact}</p>
                        <p className="text-gray-600 lg:text-base text-sm"><strong>Address:</strong> {user.address}</p>
                        {/* <p className="text-gray-600"><strong>Status:</strong> {user.status}</p> */}
                        <p className="text-gray-600 lg:text-base text-sm"><strong>Registration Date:</strong> {user.created_at.split("T")[0]}</p>
                        <p className="text-gray-600 lg:text-base text-sm"><strong>Updated Date:</strong> {user.updated_at.split("T")[0]}</p>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Userlist;
