// src/components/Layout.js
import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import logo1 from '../../assests/logo1.png';
import Header from './Header';

const Layout = () => {

    return (
        <div>
            <Header />

            <div className="flex min-h-screen">
                <div className="w-1/4 bg-gray-600 text-white p-2 md:block hidden sticky top-0 max-h-screen overflow-y-auto">
                    <div className='flex items-center justify-center'>
                        <img className='w-72 h-28' src={logo1} alt="logo" />
                    </div>
                    <ul className="mx-10 my-5 text-black/80">
                        <li className="mb-4">
                            <NavLink
                                to="/"
                                className={({ isActive }) =>
                                    `text-lg ${isActive ? 'text-white font-bold' : 'text-white/80'}`
                                }
                            >
                                Dashboard
                            </NavLink>
                        </li>
                        {/* <li className="mb-4">
                            <Link to="payments" className="text-lg">Payments</Link>
                        </li> */}
                        <li className="mb-4">
                            <NavLink
                                to="/category"
                                className={({ isActive }) =>
                                    `text-lg ${isActive ? 'text-white font-bold' : 'text-white/80'}`
                                }
                            >
                                Categories
                            </NavLink>
                        </li>
                        <li className="mb-4">
                            <NavLink
                                to="/all-courses"
                                className={({ isActive }) =>
                                    `text-lg ${isActive ? 'text-white font-bold' : 'text-white/80'}`
                                }
                            >
                                All Courses
                            </NavLink>
                        </li>
                        <li className="mb-4">
                            <NavLink
                                to="/allBanners"
                                className={({ isActive }) =>
                                    `text-lg ${isActive ? 'text-white font-bold' : 'text-white/80'}`
                                }
                            >
                                Banners
                            </NavLink>
                        </li>
                        <li className="mb-4">
                            <NavLink
                                to="/userlist"
                                className={({ isActive }) =>
                                    `text-lg ${isActive ? 'text-white font-bold' : 'text-white/80'}`
                                }
                            >
                                User List
                            </NavLink>
                        </li>
                    </ul>
                </div>
                <div className="md:w-3/4 w-full p-5 bg-gray-900">
                    <Outlet />
                </div>
            </div>
        </div>
    );
};

export default Layout;
