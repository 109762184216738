import axios from "axios";
import { base_url, token } from "../base_url";
import { toast } from "react-toastify";

export const add_lecture = async (formdata) => {

    try {
        const res = await axios.post(base_url + "/add-lecture", formdata,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        );
        return res

    } catch (error) {
        return error
    }
}


export const edit_lecture = async (id, formdata) => {

    try {
        const res = await axios.post(base_url + `/editlecture/${id}`, formdata,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        );
        return res

    } catch (error) {
        return error
    }
}
export const get_single_lecture = async (id) => {

    try {
        const res = await axios.get(base_url + `/edit-lecture/${id}`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        );
        return res

    } catch (error) {
        return error
    }
}

export const deleteLecture = async (id) => {

    try {
        const res = await axios.delete(base_url + "/deletelecture/" + id,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        );
        toast.success("Delete Course successfully!");
        return res

    } catch (error) {
        toast.error("Something went wrong. Please try again.");
        return error
    }
}

