import { useEffect, useState } from 'react';
import { home_data } from '../api_fetch/home';
import { useNavigate } from 'react-router';

export const useAuthCheck = () => {
    const [isAuthenticated, setIsAuthenticated] = useState(null);
    const [userStatus, setUserStatus] = useState(null);
    const navigate = useNavigate();
    const handleHome_data = async () => {
        const res = await home_data();
        console.log(res);

        setUserStatus(res.status);
    };

    useEffect(() => {
        handleHome_data();
    }, []);

    useEffect(() => {
        const token = localStorage.getItem('token');

        if (token && userStatus === 200) {
            setIsAuthenticated(true);
        } else if (userStatus !== null) {
            setIsAuthenticated(false);
            navigate('/login');
        }
    }, [userStatus]);

    return isAuthenticated;
};
