import React from 'react';

const Modal = ({ isOpen, close, children }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-95 flex justify-center items-center">
            <div className="bg-white p-6 md:pr-6 pr-0  rounded-lg shadow-lg w-11/12 md:w-1/2">
                {children}
            </div>
        </div>
    );
};

export default Modal;
