import React, { useEffect, useState } from 'react';
import { all_courses, deleteCourse } from '../../api_fetch/courses';
import { FaIndianRupeeSign } from "react-icons/fa6";
import { useLocation, useNavigate } from 'react-router-dom';
import { categories } from '../../api_fetch/categories';
import { Tooltip } from 'react-tooltip';

const All_Courses = () => {
    const [data, setData] = useState(null);
    const [recoveriData, setRecoveriData] = useState(null);
    const [categoryData, setCategoryData] = useState(null);
    const [selectedValue, setSelectedValue] = useState(null);

    const location = useLocation();
    const navigate = useNavigate();

    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get('query');

    const categoryFetch = async () => {
        const res = await categories();
        setCategoryData(res.data.categories)
    }

    useEffect(() => {
        const data_fetch = async () => {
            const res = await all_courses();
            setData(res.data.courses);
            setRecoveriData(res.data.courses)
        };
        categoryFetch();
        data_fetch();
        if (query) {
            setSelectedValue(query);
        }
    }, []);


    const filteredData = query
        ? data?.filter((item) => item.category_name === query)
        : data;

    const filterData = (e) => {
        const filterdata = e.target.value !== 'all' ? recoveriData?.filter((item) => item.category_name === e.target.value) : recoveriData;
        setData(filterdata)
    }

    const handleDelete = async (id) => {
        const rs = await deleteCourse(id);
        console.log(rs);
        if (rs.status === 200) {
            setData((old) => old.filter((item) => item.id !== id))
        }
    }

    return (
        <div>
            <h2 className="text-2xl font-bold mb-4 select-none">All Courses</h2>
            {!selectedValue &&
                <div className='my-4'>
                    <label htmlFor="" className='font-semibold'>Select Category</label>
                    <select className='border-2 border-gray-800 focus:border-white text-white my-2 bg-gray-800  px-4 py-2 font-bold w-full cursor-pointer rounded-lg'
                        onChange={filterData}
                        value={selectedValue}
                    >
                        <option value="all" selected>All</option>
                        {categoryData?.map((item) => (
                            <option value={item.name} className='cursor-pointer'>{item.name}</option>
                        ))}
                    </select>
                </div>
            }
            <div className='space-y-5'>
                {filteredData?.map((item, index) => {
                    return (
                        <div key={index} className='border-2 border-white p-3 rounded-xl hover:border-white/20'>
                            <div
                                className="sm:flex mb-4 cursor-pointer"
                                data-tooltip-id="lecture-tooltip"
                                data-tooltip-content={`Click to view All avaiable 'Lectures' in ${item.title} course`}
                                onClick={() => navigate(`/lectures/${item.id}`)}
                            >
                                <img
                                    src={item?.image || ""}
                                    alt={`${item.title}`}
                                    className="sm:w-[20%] w-full h-32 rounded-lg mr-4 border-2 border-gray-700"
                                />
                                <div className='sm:w-[80%] w-full'>
                                    <h1 className='font-bold'>
                                        Category Name: <span className='font-normal'>{item.category_name}</span>
                                    </h1>
                                    <h1 className='font-bold'>
                                        Title: <span className='font-normal'>{item.title}</span>
                                    </h1>
                                    <h1 className='font-bold flex items-center'>
                                        Price: <span className='font-normal flex items-center ml-1'>
                                            <FaIndianRupeeSign />{item.price}
                                        </span>
                                    </h1>
                                    <h1 className='font-bold flex items-center'>
                                        Number of Lecture: <span className='font-normal flex items-center ml-1'>
                                            {item.number_of_lectures}
                                        </span>
                                    </h1>
                                    <p className='font-bold text-justify'>
                                        Description: <span className='font-normal'>{item.description}</span>
                                    </p>
                                </div>
                                <Tooltip id="lecture-tooltip" place="top" />
                            </div>
                            <div className='text-end'>
                                <button className='bg-blue-500 rounded-lg sm:text-sm text-xs text-white font-bold hover:bg-blue-700 p-2 '
                                    onClick={() => {
                                        navigate(`/add-courses/update/${item.id}`)
                                    }}
                                >Edit Courses</button>
                                <button className='bg-green-500 rounded-lg sm:ml-2 ml-1 sm:text-sm text-xs text-white font-bold hover:bg-green-700 p-2 '
                                    onClick={() => {
                                        navigate(`/add-lecture/${item.id}`)
                                    }}
                                >
                                    Add Lucture
                                </button>
                                <button className='bg-red-500 rounded-lg sm:ml-2 ml-1 sm:text-sm text-xs text-white font-bold hover:bg-red-700 p-2 '
                                    onClick={() => handleDelete(item.id)}
                                >Delete Courses</button>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default All_Courses;
