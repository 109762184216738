import axios from "axios";
import { base_url, token } from "../base_url";

export const dashboardData = async () => {
    try {
        const res = await axios.get(base_url + "/dashboard",
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        )
        return res;
    } catch (error) {
        return error
    }
}