import React, { useEffect, useState } from 'react';
import logo1 from '../../../assests/logo1.png';
import logo2 from '../../../assests/logo2.png';
import { HiMenuAlt4 } from "react-icons/hi";
import { RxCross2 } from "react-icons/rx";
import { NavLink } from 'react-router';

const Header = () => {
    const [menu, setMenu] = useState(false);
    return (
        <div>
            <div className='md:hidden min-w-full h-20 flex items-center justify-between bg-white/80 text-white sticky top-0 pr-10'>
                <img className='w-40 h-20' src={logo1} alt="logo" />
                <div className='' onClick={() => setMenu(!menu)}>
                    {!menu &&
                        <HiMenuAlt4 className='text-3xl text-black hover:text-white font-bold cursor-pointer' />
                    }
                </div>

            </div>
            {menu && <HeaderMenu setMenu={setMenu} />}
        </div>
    )
}

export default Header

const HeaderMenu = ({ setMenu }) => {

    const [show, setShow] = useState(false);
    useEffect(() => {
        setShow(true);
    }, [])



    const handleMenuClose = (e) => {
        if (e.target === e.currentTarget) {
            setShow(false)
            setTimeout(() => {
                setMenu(false)
            }, 700);
        }
    }

    return <div className="fixed z-[100] bg-black/80 w-full text-white top-0 min-h-screen overflow-y-auto"
        onClick={(e) => handleMenuClose(e)}
    >
        <div className={`fixed left-0 top-0 w-1/2 bg-gray-600 min-h-screen sm:p-5 p-2 transition-all duration-700 transform ${show ? "translate-x-0 opacity-100" : "-translate-x-full opacity-0"}`}>
            <div className='flex items-center justify-center'>
                <img className='w-24 h-24' src={logo2} alt="logo" />
            </div>
            <ul className="sm:mx-10 mx-5 my-5 text-black/80">
                <li className="mb-4">
                    <NavLink
                        to="/"
                        className={({ isActive }) =>
                            `text-lg ${isActive ? 'text-white font-bold' : 'text-white/80'}`
                        }
                        onClick={() => {
                            setShow(false)
                            setTimeout(() => {
                                setMenu(false)
                            }, 700);
                        }}
                    >
                        Dashboard
                    </NavLink>
                </li>
                <li className="mb-4">
                    <NavLink
                        to="/category"
                        className={({ isActive }) =>
                            `text-lg ${isActive ? 'text-white font-bold' : 'text-white/80'}`
                        }
                        onClick={() => {
                            setShow(false)
                            setTimeout(() => {
                                setMenu(false)
                            }, 700);
                        }}
                    >
                        Categories
                    </NavLink>
                </li>
                <li className="mb-4">
                    <NavLink
                        to="/all-courses"
                        className={({ isActive }) =>
                            `text-lg ${isActive ? 'text-white font-bold' : 'text-white/80'}`
                        }
                        onClick={() => {
                            setShow(false)
                            setTimeout(() => {
                                setMenu(false)
                            }, 700);
                        }}
                    >
                        All Courses
                    </NavLink>
                </li>

                <li className="mb-4">
                    <NavLink
                        to="/allBanners"
                        className={({ isActive }) =>
                            `text-lg ${isActive ? 'text-white font-bold' : 'text-white/80'}`
                        }
                        onClick={() => {
                            setShow(false)
                            setTimeout(() => {
                                setMenu(false)
                            }, 700);
                        }}
                    >
                        Banners
                    </NavLink>
                </li>
                {/* <li className="mb-4">
            <Link to="payments" className="text-lg">Payments</Link>
        </li> */}
                <li className="mb-4">
                    <NavLink
                        to="/userlist"
                        className={({ isActive }) =>
                            `text-lg ${isActive ? 'text-white font-bold' : 'text-white/80'}`
                        }
                        onClick={() => {
                            setShow(false)
                            setTimeout(() => {
                                setMenu(false)
                            }, 700);
                        }}
                    >
                        User List
                    </NavLink>
                </li>
            </ul>
        </div>

        <div className='absolute top-5 right-5'>
            <RxCross2
                className='text-3xl text-white hover:text-red-500 font-bold cursor-pointer'
                onClick={() => {
                    setShow(false); // Start the closing animation
                    setTimeout(() => {
                        setMenu(false); // Hide the menu after the animation
                    }, 700); // Match this duration with the transition duration
                }}
            />
        </div>
    </div>
}