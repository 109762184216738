import axios from "axios";
import { base_url, token } from "../base_url";
import { toast } from "react-toastify";

export const users = async () => {
    try {
        const res = await axios.get(`${base_url}/users`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        toast.success("Get User Details successfully!");
        return res;
    } catch (error) {
        toast.error("Something went wrong. Please try again.");
        return error;
    }
}