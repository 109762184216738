import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { add_category } from '../../../api_fetch/categories';

const AddCategory = () => {
    const [course, setCourse] = useState({
        title: '',
        description: '',
        imageUrl: null,
    });
    const navigate = useNavigate();

    const handleChange = (e) => {
        const { name, value, files } = e.target;

        if (name === 'imageUrl') {
            const file = files[0];
            if (file) {
                const allowedTypes = ["image/jpeg", "image/png", "image/jpg", "image/gif"];
                if (!allowedTypes.includes(file.type)) {
                    alert("The image field must be a file of type: jpeg, png, jpg, gif.");
                    return;
                }
                setCourse({
                    ...course,
                    imageUrl: file,
                });
            } else {
                alert("Please select an image.");
            }
        } else {
            setCourse({
                ...course,
                [name]: value,
            });
        }
    };




    const handleSubmit = async (e) => {
        e.preventDefault();

        const formdata = new FormData();
        formdata.append("name", course.title);
        formdata.append("description", course.description);
        if (course.imageUrl) {
            formdata.append("image", course.imageUrl);
        }

        try {
            const res = await add_category(formdata);
            if (res.status === 201 || res.status === 200) {
                navigate('/category');
            }
        } catch (error) {
            console.error('Error adding course:', error);
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen">
            <div className="w-full max-w-lg bg-gray-800 p-8 rounded-lg shadow-lg">
                <h2 className="text-3xl font-bold text-white mb-6 text-center capitalize">Add new Category</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label htmlFor="title" className="block text-white font-semibold mb-2">Name</label>
                        <input
                            type="text"
                            id="title"
                            name="title"
                            value={course.title}
                            onChange={handleChange}
                            className="w-full px-4 py-2 text-white bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter Category Title"
                        />
                    </div>


                    <div className="mb-4">
                        <label htmlFor="imageUrl" className="block text-white font-semibold mb-2">Select Image</label>
                        <input
                            type="file"
                            id="imageUrl"
                            name="imageUrl"
                            accept="image/*" // Accept only images
                            onChange={handleChange}
                            className="w-full px-4 py-2 text-white bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>

                    <div>
                        {(course.imageUrl) && <img src={URL.createObjectURL(course.imageUrl)} alt="Selected Course" className="mt-4 w-32 h-32 object-cover" />}
                    </div>

                    <div className="mb-6">
                        <label htmlFor="description" className="block text-white font-semibold mb-2">Description</label>
                        <textarea
                            id="description"
                            name="description"
                            value={course.description}
                            onChange={handleChange}
                            className="w-full px-4 py-2 text-white bg-gray-700 border border-gray-600 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                            placeholder="Enter Category Description"
                            rows="5"
                        />
                    </div>

                    <div className="text-center">
                        <button
                            type="submit"
                            className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        >
                            Add Category
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddCategory;
