import React, { useEffect, useState } from 'react';
import { categories, deleteCategory } from '../../api_fetch/categories';
import { useLocation, useNavigate } from 'react-router-dom';

const Categories = () => {
    const [data, setData] = useState();
    const navigate = useNavigate();
    const location = useLocation();

    const fetch_data = async () => {
        const res = await categories();
        setData(res?.data.categories);
    };

    useEffect(() => {
        fetch_data();
    }, [location.pathname]);

    const handleDelete = async (id) => {
        const rs = await deleteCategory(id);
        console.log(rs);
        if (rs.status === 200) {
            setData((old) => old.filter((item) => item.id !== id))
        }

    }

    return (
        <div>
            <h2 className="text-2xl font-bold mb-4 select-none">Categories</h2>

            <div className='text-end'>
                <button className='bg-green-500 font-semibold py-1 px-3 mb-5 mr-10 rounded-md hover:bg-green-700'
                    onClick={() => {
                        navigate("/add-category")
                    }}

                >Add New Category</button>
            </div>
            <div className='space-y-5'>
                {data?.map((item, index) => (
                    <div
                        key={index}
                        className='border-2 border-white p-3'
                    >
                        <div className="flex items-center mb-4 cursor-pointer"
                            onClick={() => {
                                navigate(`/all-courses?query=${encodeURIComponent(item.name)}`);
                            }}
                        >
                            <img
                                src={item.image_url ? item.image_url : ""}
                                alt={`${item.name}`}
                                className="w-16 h-16 rounded-full mr-4 border-2 border-white"
                            />
                            <div>
                                <h1>{item.name}</h1>
                                <p>{item.description}</p>
                            </div>
                        </div>
                        <div className='text-end'>
                            <button className='bg-white/80 rounded-l-lg text-sm text-black p-2 hover:bg-green-500 hover:text-white'
                                onClick={() => {
                                    navigate(`/add-courses/${item.id}`);
                                }}

                            >Add Courses</button>
                            <button className='bg-red-500/80 rounded-r-lg ml-1 text-sm text-white p-2 hover:bg-red-800 hover:text-white'
                                onClick={() => handleDelete(item.id)}

                            >Delete Catrgory</button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Categories;
