import axios from "axios";
import { base_url, token } from "../base_url";
import { toast } from "react-toastify";

export const getbanners = async () => {
    try {
        const res = await axios.get(base_url + "/getbanner",
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        )
        toast.success("Get all Banners successfully!");
        return res;
    } catch (error) {
        toast.error("Something went wrong. Please try again.");
        return error
    }
}

export const deletebanners = async (id) => {
    try {
        const res = await axios.delete(base_url + "/banner/" + id,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        )
        toast.success("Banner delete successfully!");
        return res;
    } catch (error) {
        toast.error("Something went wrong. Please try again.");
        return error
    }
}

export const addbanner = async (selectImage) => {
    const formdata = new FormData();
    formdata.append("image", selectImage)
    try {
        const res = await axios.post(base_url + "/add-banner",
            formdata,
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });

        return res;
    } catch (error) {
        return error
    }
}