import React, { useEffect, useRef, useState } from 'react';
import { singleCourse } from '../../api_fetch/courses';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { deleteLecture } from '../../api_fetch/lecture';

const Lectures = () => {
    const [data, setData] = useState(null);
    const [playingVideo, setPlayingVideo] = useState(null); // Track the currently playing video
    const params = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const ratingRef = useRef(null);

    useEffect(() => {
        const data_fetch = async () => {
            const res = await singleCourse(params.id);
            setData(res.data.course);
            const rate = await res?.data?.course?.ratings.reduce((acc, curr) => acc + curr.rating, 0);
            ratingRef.current = rate;
        };
        data_fetch();
    }, [params.id]);

    const handleDelete = async (id) => {
        const rs = await deleteLecture(id);
        if (rs.status === 200) {
            setData((old) => ({
                ...old,
                lectures: old.lectures.filter((item) => item.id !== id),
            }));
        }
    };

    const handlePlayVideo = (id) => {
        setPlayingVideo(id); // Set the video ID to play
    };

    return (
        <div>
            <h2 className="text-2xl font-bold mb-4 select-none">Lecture</h2>
            {data && (
                <div className="my-5 bg-gray-800 px-3 py-2 rounded-xl space-y-3">
                    <h1 className="font-bold border-2 border-gray-700 p-2 rounded">
                        Category Name: <span className="font-normal">{data?.category?.name}</span>
                    </h1>
                    <h1 className="font-bold border-2 border-gray-700 p-2 rounded">
                        Course Name: <span className="font-normal">{data.title}</span>
                    </h1>
                </div>
            )}
            <div className="space-y-5">
                {data?.lectures?.map((item, index) => (
                    <div key={index} className="border-2 border-white p-3 rounded-lg">
                        <div className="sm:flex mb-4 relative">
                            {playingVideo === item.id ? (
                                <video
                                    className="sm:w-[20%] w-full sm:h-full h-60 object-cover rounded-lg border-2 border-white"
                                    controls
                                    autoPlay
                                    onEnded={() => setPlayingVideo(null)} // Reset after video ends
                                >
                                    <source src={item.video_url} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>
                            ) : (
                                <div className="relative w-full sm:h-32 h-60 sm:w-[20%]">
                                    <img
                                        src={item.thumbnail ? item.thumbnail : ""}
                                        alt={`${item.title}`}
                                        className="absolute w-full h-full object-cover rounded-lg border-2 border-white"
                                    />
                                    <button
                                        className="absolute inset-0 flex items-center justify-center text-white bg-black bg-opacity-50 rounded-lg text-4xl"
                                        onClick={() => handlePlayVideo(item.id)}
                                    >
                                        ▶
                                    </button>
                                </div>
                            )}
                            <div className="sm:w-[80%] sm:ml-5 sm:mt-0 mt-2 w-full">
                                <h1 className="font-bold">
                                    Title: <span className="font-normal">{item.title}</span>
                                </h1>
                                <h1 className="font-bold">
                                    Type: <span className="font-normal">{item.type}</span>
                                </h1>
                                <p className="font-bold text-justify">
                                    Description: <span className="font-normal">{item.description}</span>
                                </p>
                                <h1 className="font-bold">
                                    Rating:
                                    <span className="font-normal ml-2 text-yellow-400">
                                        {'★'.repeat(ratingRef.current)}{'☆'.repeat(5 - ratingRef.current)}
                                    </span>
                                </h1>
                            </div>
                        </div>
                        <div className="text-end">
                            <button
                                className="bg-blue-500 rounded-lg sm:text-sm text-xs text-white font-bold hover:bg-blue-700 p-2"
                                onClick={() => {
                                    navigate(`/add-lecture/update/${item.id}`);
                                    dispatch({
                                        type: "LECTURE_DATA",
                                        data: item,
                                    });
                                }}
                            >
                                Edit Lecture
                            </button>
                            <button
                                className="bg-red-500 rounded-lg sm:ml-2 ml-1 sm:text-sm text-xs text-white font-bold hover:bg-red-700 p-2"
                                onClick={() => handleDelete(item.id)}
                            >
                                Delete Lecture
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <div>
                {data && (!data.lectures || data.lectures.length === 0) && (
                    <div className="text-center mt-10">No data available</div>
                )}
            </div>
        </div>
    );
};

export default Lectures;
