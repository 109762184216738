import { useState } from 'react';
import Modal from '../Modal';
import { useNavigate } from 'react-router';
import logo2 from '../../assests/logo2.png'
import loginLogo from '../../assests/loginLogo1.png'
import { login } from '../../api_fetch/login';

const Login = () => {
    const [isModalOpen, setIsModalOpen] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const res = await login(email, password);
        localStorage.setItem("token", res.token)
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 2000)

        console.log(res);
        

        if (res.success === true) {
            setLoading(false);
            navigate("/");
            window.location.reload();
        }
    };

    return (
        <>
            {loading ? (
                <div className="flex bg-gray-600 bg-opacity-95 justify-center items-center h-screen text-white">
                    <div>Loading...</div>
                </div>
            ) : (
                <Modal isOpen={isModalOpen} close={() => setIsModalOpen(false)}>
                    <h2
                        className="text-2xl font-extrabold text-center mb-6 text-black text-cursive"
                        style={{ fontFamily: '-moz-initial' }}
                    >
                        Login
                    </h2>
                    <div className="flex items-center">
                        <div className="w-1/2 -ml-5 -mt-10 px-5">
                            <img
                                // https://cdni.iconscout.com/illustration/premium/thumb/admin-login-illustration-download-in-svg-png-gif-file-formats--analytics-logo-action-people-illustrations-4297423.png
                                className='md:w-60 w-40 h-60 '
                                src={loginLogo}
                                alt="login logo"
                            />
                        </div>
                        <form onSubmit={handleSubmit} className="space-y-4 text-black w-1/2">
                            <div>
                                <label htmlFor="email" className="block text-sm font-bold">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    className="w-full p-2 border border-gray-300 rounded-md"
                                    required
                                />
                            </div>
                            <div>
                                <label htmlFor="password" className="block text-sm font-bold">
                                    Password
                                </label>
                                <input
                                    type="password"
                                    id="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    className="w-full p-2 border border-gray-300 rounded-md"
                                    required
                                />
                            </div>
                            <button
                                type="submit"
                                className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-700"
                            >
                                Login
                            </button>
                        </form>
                    </div>
                </Modal>
            )}
        </>
    );
};

export default Login;
