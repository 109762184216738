import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { addbanner } from '../../../api_fetch/banner';

const AddBanner = () => {
    const [selectImage, setSelectImage] = useState('');
    const navigate = useNavigate();
    const handleImage = (e) => {
        const file = e.target.files[0];
        const allowedTypes = ["image/jpeg", "image/png", "image/jpg", "image/gif"];

        if (file && allowedTypes.includes(file.type)) {
            setSelectImage(file);
        } else {
            alert("The image field must be a file of type: jpeg, png, jpg, gif.");
        }
    };

    const handleBanner = async () => {
        try {
            const response = await addbanner(selectImage);
            if (response.status === 200 || response.status === 201) {
                navigate("/allBanners")
            }
        } catch (error) {
            console.log(error);
        }
    }



    return (
        <div className='my-5 relative min-h-[90vh] md:min-h-[85vh]'>
            <h2 className="text-2xl font-bold mb-4 select-none">Add Banner</h2>
            <div className='mt-10'>

                <div className='border-dotted border-2 border-gray-500 p-4 rounded mt-5'>
                    <label className='cursor-pointer flex flex-col items-center justify-center'>
                        <input
                            type='file'
                            accept='image/*'
                            className='hidden'
                            onChange={handleImage}
                        />
                        <div className='text-black bg-gray-200 h-20 flex items-center justify-center w-full rounded'>
                            Select Banner Image
                        </div>
                    </label>
                </div>
                <div className='mt-10'>
                    {selectImage && (
                        <img
                            src={URL.createObjectURL(selectImage)} // Create URL for image preview
                            width={60}
                            height={60}
                            alt="Selected image preview" // Use an appropriate alt text
                            className='rounded w-full md:h-60 h-48' // Optional: add rounded corners to the preview
                        />
                    )}
                </div>

            </div>
            <div className="text-center absolute bottom-0 left-1/2 transform -translate-x-1/2 w-full">
                <button className={`text-white rounded px-7 py-2 font-bold ${!selectImage ? "bg-gray-300 text-black" : "bg-green-500"}`}
                    disabled={!selectImage}
                    onClick={handleBanner}
                >
                    Add
                </button>
            </div>
        </div>
    )
}

export default AddBanner