import axios from "axios"
import { base_url } from "../base_url"
import { toast } from "react-toastify";

export const login = async (email, password) => {
    const formData = new FormData();

    formData.append("email", email);
    formData.append("password", password);

    try {
        const res = await axios.post(`${base_url}/admin-login`, formData);
        toast.success("Admin Login successfully!");
        return res.data
    } catch (error) {
        toast.error("Something went wrong. Please try again.");
        return error
    }
}