import axios from "axios"
import { base_url, token } from "../base_url"
import { toast } from "react-toastify";


export const all_courses = async () => {
    try {
        const res = await axios.get(base_url + "/allcourses",
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        );
        return res

    } catch (error) {
        return error
    }
}

export const add_courses = async (formdata) => {

    try {
        const res = await axios.post(base_url + "/add-course", formdata,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        );
        toast.success("Add Course successfully!");
        return res

    } catch (error) {
        toast.error("Something went wrong. Please try again.");
        return error
    }
}

export const edit_course = async (formdata, id) => {

    try {
        const res = await axios.post(base_url + `/editcourse/${id}`, formdata,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        );
        toast.success("Update Course successfully!");
        return res

    } catch (error) {
        toast.error("Something went wrong. Please try again.");
        return error
    }
}

export const singleCourse = async (id) => {

    try {
        const res = await axios.get(base_url + "/course/" + id,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        );

        return res

    } catch (error) {
        return error
    }
}

export const deleteCourse = async (id) => {

    try {
        const res = await axios.delete(base_url + "/deletecourse/" + id,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        );
        toast.success("Delete Course successfully!");
        return res

    } catch (error) {
        toast.error("Something went wrong. Please try again.");
        return error
    }
}



