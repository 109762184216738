import { useEffect, useRef } from 'react';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

function PieChart({ product, data }) {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  // Custom transparentize function
  function transparentize(color, opacity) {
    const alpha = Math.max(0, Math.min(1, opacity));
    return color.replace(/rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+))?\)/, `rgba($1, $2, $3, ${alpha})`);
  }

  // Function to determine color based on data value
  function colorize(opaque, hover, ctx) {
    const v = ctx.parsed;
    const c = v < -50 ? '#D60000'
      : v < 0 ? '#F46300'
        : v < 50 ? '#0358B6'
          : '#44DE28';

    const opacity = hover ? 1 - Math.abs(v / 150) - 0.2 : 1 - Math.abs(v / 150);

    return opaque ? c : transparentize(c, opacity);
  }

  function hoverColorize(ctx) {
    return colorize(false, true, ctx);
  }

  function calculateTotal(data) {
    return Object.values(data).reduce((sum, value) => sum + value, 0);
  }

  const initializeChartData = () => {
    const bookingData = data?.payments;

    const numericData = Object.values(bookingData).map(value => parseFloat(value) || 0);

    const totalBookings = calculateTotal(numericData);

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const centerTextPlugin = {
      id: 'centerTextPlugin',
      afterDraw(chart) {
        const { ctx, width, height } = chart;
        ctx.save();
        ctx.font = 'bold 25px Arial';
        ctx.fillStyle = 'white';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(data?.payments?.total_payments_this_year, width / 2, height / 3.3);

        ctx.font = '10px Arial';
        ctx.fillText("Payments", width / 2, height / 2.5);
        ctx.restore();
      }
    };

    chartInstance.current = new Chart(chartRef.current, {
      type: 'pie',
      data: {
        labels: Object.keys(bookingData).map(label =>
          label.split("_").map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ")
        ),
        datasets: [{
          data: numericData,
          backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0'],
        }]
      },
      options: {
        rotation: -Math.PI / 2,
        cutout: '60%',
        plugins: {
          legend: {
            display: true,
            position: 'bottom',
          },
          tooltip: {
            enabled: true,
            callbacks: {
              label: function (tooltipItem) {
                const labelIndex = tooltipItem.dataIndex;
                const label = chartInstance.current.data.labels[labelIndex];
                const value = tooltipItem.raw || 0;
                return `${label}: ${value}`;
              }
            }
          }
        },
        elements: {
          arc: {
            backgroundColor: colorize.bind(null, false, false),
            hoverBackgroundColor: hoverColorize
          }
        }
      },
      plugins: [centerTextPlugin]
    });
  };

  useEffect(() => {
    initializeChartData();
    return () => {
      if (chartInstance.current) chartInstance.current.destroy();
    };
  }, [product]);

  return <canvas ref={chartRef} width={600} height={600} />;
}

export default PieChart;
