import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { add_lecture, edit_lecture, get_single_lecture } from '../../../api_fetch/lecture';

const AddLecture = () => {
    const [lecture, setLecture] = useState({
        title: '',
        price: '',
        description: '',
        imageUrl: null,
        videoUrl: null,
        type: ''
    });

    const [givenImage, setGivenImage] = useState('');

    const [videoPreview, setVideoPreview] = useState(null);  // For storing the video preview URL

    const params = useParams();
    const navigate = useNavigate();

    const getSingleLectureData = async (id) => {
        const rs = await get_single_lecture(id)

        if (rs?.data) {
            setLecture(old => (
                {
                    ...old,
                    title: rs.data.title,
                    description: rs.data.description,
                    type: rs.data.type,
                    videoUrl: rs.data.video_url,
                }))
            setGivenImage(rs.data.thumbnail)
        }


    }

    useEffect(() => {
        if (params.update) {
            getSingleLectureData(params.id);
        }
    }, [params])

    const handleChange = (e) => {
        const { name, value, files } = e.target;

        if (name === 'imageUrl') {
            const file = files[0];
            if (file) {
                setLecture({ ...lecture, imageUrl: file });
            }
        } else if (name === 'videoUrl') {
            const file = files[0];
            if (file) {
                // Revoke the old object URL if it exists to prevent memory leaks
                if (videoPreview) {
                    URL.revokeObjectURL(videoPreview);
                }
                const newPreviewUrl = URL.createObjectURL(file);
                setLecture({ ...lecture, videoUrl: file });
                setVideoPreview(newPreviewUrl);
            } else {
                // Clear video preview if no file is selected
                setLecture({ ...lecture, videoUrl: null });
                setVideoPreview(null);
            }
        } else {
            setLecture({ ...lecture, [name]: value });
        }
    };

    // useEffect(() => {
    //     if (params.update) {
    //         setLecture(old => (
    //             {
    //                 ...old,
    //                 title: selector.title,
    //                 description: selector.description,
    //                 type: selector.type,
    //                 videoUrl: selector.video_url,
    //                 image: selector.thumbnail
    //             }
    //         ))
    //         setGivenImage(selector.thumbnail)
    //     }
    // }, [params.update])

    useEffect(() => {
        return () => {
            if (videoPreview) {
                URL.revokeObjectURL(videoPreview);
            }
        };
    }, [videoPreview]);

    const [prevLocation, setPrevLocation] = useState(null);

    useEffect(() => {
        const handlePopState = () => {
            const previousUrl = document.referrer; // Gets the previous URL
            setPrevLocation(previousUrl);
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            let res;
            const formData = new FormData();
            formData.append("title", lecture.title);
            formData.append("description", lecture.description);
            formData.append("type", lecture.type);
            if (lecture.imageUrl) {
                formData.append("thumbnail", lecture.imageUrl);
            }
            if (lecture.videoUrl) {
                formData.append("video", lecture.videoUrl);
            }
            if (params.update) {
                res = await edit_lecture(params.id, formData);
            }
            else {

                formData.append("course_id", params.id);
                res = await add_lecture(formData);
            }
            if (res.status === 201) {
                navigate(`/lectures/${params.id}`);
            }
            if (res.status === 200) {
                navigate("/all-courses");
            }
        } catch (error) {
            console.error('Error adding course:', error);
        }
    };

    return (
        <div className="flex justify-center items-center min-h-screen">
            <div className="w-full max-w-lg bg-gray-800 p-8 rounded-lg shadow-lg">
                <h2 className="text-3xl font-bold text-white mb-6 text-center">{params.update ? "Update " : "Add New "} Lecture</h2>
                <form onSubmit={handleSubmit}>
                    {/* Title Input */}
                    <div className="mb-4">
                        <label htmlFor="title" className="block text-white font-semibold mb-2">Lecture Title</label>
                        <input
                            type="text"
                            id="title"
                            name="title"
                            value={lecture.title} 
                            onChange={handleChange}
                            className="w-full px-4 py-2 text-white bg-gray-700 border rounded-md focus:ring-blue-500"
                            placeholder="Enter Lecture Title"
                        />
                    </div>
                    <div className="mb-4">
                        <label htmlFor="title" className="block text-white font-semibold mb-2">Lecture Type</label>
                        <select
                            className="w-full px-4 py-2 text-white bg-gray-700 border rounded-md focus:ring-blue-500"
                            value={lecture.type || ''}
                            onChange={(e) => {
                                setLecture((old) => ({ ...old, type: e.target.value }))
                            }}
                        >
                            <option disabled value=''>Select type of lecture</option>
                            <option value="live">Live</option>
                            <option value="recorded">Recorded</option>
                        </select>
                    </div>

                    {/* Price Input */}
                    {/* <div className="mb-4">
                        <label htmlFor="price" className="block text-white font-semibold mb-2">Lecture Price</label>
                        <input
                            type="text"
                            id="price"
                            name="price"
                            value={lecture.price}
                            onChange={handleChange}
                            className="w-full px-4 py-2 text-white bg-gray-700 border rounded-md focus:ring-blue-500"
                            placeholder="Enter Lecture Price"
                        />
                    </div> */}

                    {/* Image Upload */}
                    <div className="mb-4">
                        <label htmlFor="imageUrl" className="block text-white font-semibold mb-2">Select Lecture Thumbnail</label>
                        <input
                            type="file"
                            id="imageUrl"
                            name="imageUrl"
                            accept="image/*"
                            onChange={handleChange}
                            className="w-full text-white bg-gray-700 border rounded-md"
                        />
                        {(lecture.imageUrl || givenImage) && (
                            <img src={(givenImage && !lecture.imageUrl) ? givenImage : URL.createObjectURL(lecture.imageUrl)} alt="Selected lecture thumbnail" className="mt-4 w-32 h-32 object-cover" />
                        )}
                    </div>

                    {/* Video Upload */}
                    {!params.update && (<>
                        {!videoPreview && (
                            <div className="mb-4">
                                <label htmlFor="videoUrl" className="block text-white font-semibold mb-2">Select Lecture Video</label>
                                <input
                                    type="file"
                                    id="videoUrl"
                                    name="videoUrl"
                                    accept="video/*"
                                    onChange={handleChange}
                                    className="w-full text-white bg-gray-700 border rounded-md"
                                />
                            </div>
                        )}

                        {/* Video Preview */}
                        {videoPreview && (
                            <div className="mt-4">
                                <h1 className="block text-white font-semibold mb-2">Selected Lecture Video</h1>
                                <video controls width="100%" className="rounded-md">
                                    <source src={videoPreview} type={lecture.videoUrl.type} />
                                    Your browser does not support the video tag.
                                </video>
                                <div className="flex justify-end mt-5 w-full">
                                    <h1
                                        className="bg-red-500 w-fit py-1 px-3 rounded hover:bg-red-800 cursor-pointer"
                                        onClick={() => {
                                            setVideoPreview(null)
                                            setLecture((old) => ({ ...old, videoUrl: null }))
                                        }
                                        }
                                    >
                                        Remove
                                    </h1>

                                </div>
                            </div>
                        )}
                    </>
                    )}

                    {/* Description Input */}
                    <div className="mb-6">
                        <label htmlFor="description" className="block text-white font-semibold mb-2">Lecture Description</label>
                        <textarea
                            id="description"
                            name="description"
                            value={lecture.description}
                            onChange={handleChange}
                            className="w-full px-4 py-2 text-white bg-gray-700 border rounded-md focus:ring-blue-500"
                            placeholder="Enter Lecture Description"
                            rows="5"
                        />
                    </div>

                    {/* Submit Button */}
                    <div className="text-center">
                        <button
                            type="submit"
                            className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:ring-blue-500"
                        >
                            {params.update ? "Update " : "Add "}  Lecture
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddLecture;
