import axios from "axios"
import { base_url, token } from "../base_url"

export const home_data = async () => {
    try {
        const res = await axios.get(`https://arhantfoundation.org/app/api/admin-check-session`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return res;
    } catch (error) {
        return error;
    }
}